import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../context/AuthContext';

const getDocumentBackgroundClass = (status) => {
  switch (status) {
    case 'pending':
      return 'bg-pending';
    case 'complete':
      return 'bg-complete';
    case 'incomplete':
      return 'bg-incomplete';
    default:
      return '';
  }
};

const TitleList = ({
  titles,
  onDocumentUpload,
  onDocumentDelete,
  onCommentSubmit,
  onCommentChange,
  newComments,
  onCommentDelete,
  handleChangeDocumentStatus,
}) => {
  const { authTokens } = useContext(AuthContext);
  const userRole = authTokens?.userRole;
  const [isProcessing, setIsProcessing] = useState(false);

  const handleStatusChange = async (titleId, documentId, newStatus) => {
    if (isProcessing) return; // Evitar múltiples clics

    setIsProcessing(true);
    try {
      await handleChangeDocumentStatus(titleId, documentId, newStatus);
    } catch (error) {
      console.error('Error al cambiar el estado del documento:', error);
    } finally {
      setTimeout(() => {
        setIsProcessing(false);
      }, 500); // Asegurar que el DOM se actualice antes de permitir otra acción
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {titles.length > 0 ? (
        titles.map((title) => (
          <div key={title.id} className="bg-white shadow-lg rounded-lg p-4 m-2">
            <h2 className="text-xl font-bold mb-2">{title.title_name}</h2>
            {title.documents && title.documents.map((document) => (
              <div
                key={document.id}
                className={`mb-2 p-4 rounded-lg ${getDocumentBackgroundClass(document.status)}`}
              >
                <p className="text-sm">
                  {document.document_title}
                </p>
                <p className="text-xs text-gray-600">
                  {new Date(document.created_at).toLocaleString()}
                </p>
                {(userRole === 'admin' || userRole === 'super_admin') && (
                  <button
                    type="button"
                    onClick={() => {
                      if (window.confirm('¿Estás seguro de que quieres eliminar este documento?')) {
                        onDocumentDelete(title.id, document.id);
                      }
                    }}
                    className="text-red-500 hover:text-red-700 text-xs mr-2"
                    disabled={isProcessing} // Deshabilitar botón durante la operación
                  >
                    Eliminar
                  </button>
                )}
                <a
                  href={document.file_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="text-blue-500 hover:text-blue-700 text-xs"
                >
                  Descargar
                </a>
                <div className="flex justify-start mt-2">
                  {(userRole === 'admin' || userRole === 'super_admin') && (
                    <>
                      <button
                        type="button"
                        onClick={() => handleStatusChange(title.id, document.id, 'complete')}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                        disabled={isProcessing} // Deshabilitar botón durante la operación
                      >
                        Completo
                      </button>
                      <button
                        type="button"
                        onClick={() => handleStatusChange(title.id, document.id, 'incomplete')}
                        className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        disabled={isProcessing} // Deshabilitar botón durante la operación
                      >
                        Incompleto
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}
            <div className="my-2">
              <input
                type="file"
                onChange={(e) => onDocumentUpload(e, title.id)}
                className={`
                  text-sm text-gray-500 file:mr-4 file:py-2 
                  file:px-4 file:rounded-full file:border-0 
                  file:text-sm file:font-semibold file:bg-blue-50 
                  file:text-blue-700 hover:file:bg-blue-100
                `}
              />
            </div>
            <form onSubmit={(e) => onCommentSubmit(e, title.id)} className="my-2">
              <textarea
                value={newComments[title.id] || ''}
                onChange={(e) => onCommentChange(e, title.id)}
                className="w-full p-2 text-sm text-gray-700 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                type="submit"
                className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                disabled={isProcessing} // Deshabilitar durante la operación
              >
                Enviar Comentario
              </button>
            </form>
            <div>
              {title.comments && title.comments.map((comment) => (
                <div key={comment.id} className="my-2">
                  <p className="text-sm">
                    {comment.content}
                  </p>
                  <p className="text-xs text-gray-600">
                    {new Date(comment.created_at).toLocaleString()}
                  </p>
                  {userRole === 'super_admin' && (
                    <button
                      type="button"
                      onClick={() => {
                        if (window.confirm('¿Estás seguro de que quieres eliminar este comentario?')) {
                          onCommentDelete(title.id, comment.id);
                        }
                      }}
                      className="text-red-500 hover:text-red-700 text-xs"
                      disabled={isProcessing} // Deshabilitar durante la operación
                    >
                      Eliminar
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <p className="col-span-3 text-center">Cargando requerimientos...</p>
      )}
    </div>
  );
};

TitleList.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title_name: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      document_title: PropTypes.string,
      created_at: PropTypes.string.isRequired,
    })).isRequired,
    comments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
  onDocumentUpload: PropTypes.func.isRequired,
  onDocumentDelete: PropTypes.func.isRequired,
  handleChangeDocumentStatus: PropTypes.func.isRequired,
  onCommentSubmit: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  onCommentDelete: PropTypes.func.isRequired,
  newComments: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default TitleList;
