const menuItemsByService = {
  'Anexo 30 y 31 2022': [
    { name: 'Datos Generales 2022', path: '/anexo30Y31ano2022datosgenerales' },
    { name: 'Sistemas De Medicion 2022', path: '/anexo30Y31ano2022sistemasdemedicion' },
    { name: 'SGM 2022', path: '/anexo30Y31Ano2022SGM' },
    { name: 'Programa Informatico 2022', path: '/anexo30Y31Ano2022ProgramaInformatico' },
    { name: 'Evidencias Fotograficas 2022', path: '/anexo30Y31Ano2022EvidenciasFotograficas' },
    { name: 'Inicio', path: '/dashboard' },
  ],
  'Anexo 30 y 31 2023': [
    { name: 'Datos Generales 2023', path: '/anexo30Y31ano2023datosgenerales' },
    { name: 'Sistemas De Medicion 2023', path: '/anexo30Y31ano2023sistemasdemedicion' },
    { name: 'SGM 2023', path: '/anexo30Y31Ano2023SGM' },
    { name: 'Programa Informatico 2023', path: '/anexo30Y31Ano2023ProgramaInformatico' },
    { name: 'Evidencias Fotograficas 2023', path: '/anexo30Y31Ano2023EvidenciasFotograficas' },
    { name: 'Inicio', path: '/dashboard' },
  ],
  'Anexo 30 y 31 2024': [
    { name: 'Datos Generales 2024', path: '/anexo30Y31ano2024datosgenerales' },
    { name: 'Sistemas De Medicion 2024', path: '/anexo30Y31ano2024sistemasdemedicion' },
    { name: 'SGM 2024', path: '/anexo30Y31Ano2024SGM' },
    { name: 'Programa Informatico 2024', path: '/anexo30Y31Ano2024ProgramaInformatico' },
    { name: 'Evidencias Fotograficas 2024', path: '/anexo30Y31Ano2024EvidenciasFotograficas' },
    { name: 'Inicio', path: '/dashboard' },
  ],
  'Dictamen de Diseño': [
    { name: 'Documentos', path: '/dictamendedisenodocumentos' },
    { name: 'Planos', path: '/dictamendedisenoplanos' },
    { name: 'Inicio', path: '/dashboard' },
  ],
  'NOM-016-CRE-2016': [
    { name: 'Información General', path: '/nom016informaciongeneral' },
    { name: 'Documentación', path: '/nom016documentacion' },
    { name: 'Inicio', path: '/dashboard' },
  ],
  'Anexo 30 y 31 para transporte o distribución 2024': [
    { name: 'Datos Generales', path: '/inspeccion30y31ano2024datosgenerales' },
    { name: 'Sistemas de medición', path: '/inspeccion30y31ano2024sistemasdemedicion' },
    { name: 'SGM', path: '/inspeccion30y31ano2024sgm' },
    { name: 'Programa Informatico', path: '/inspeccion30y31ano2024programainformatico' },
    { name: 'Políticas', path: '/inspeccion30y31ano2024politicas' },
    { name: 'Inicio', path: '/dashboard' },
  ],
};

export default menuItemsByService;
