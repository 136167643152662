// src/pages/UserServicesReport.js

import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { fetchUserServicesReport } from '../services/apiService';

const UserServicesReport = () => {
  const { authTokens } = useContext(AuthContext);
  const [userServices, setUserServices] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchUserServicesReport(authTokens);
      setUserServices(data);
    };

    loadData();
  }, [authTokens]);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Reporte de Altas de Servicios</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 bg-gray-200">Fecha de Alta</th>
            <th className="py-2 px-4 bg-gray-200">Usuario</th>
            <th className="py-2 px-4 bg-gray-200">Servicio</th>
          </tr>
        </thead>
        <tbody>
          {userServices.map((userService) => (
            <tr key={userService.id}>
              <td className="py-2 px-4 border">{new Date(userService.created_at).toLocaleDateString()}</td>
              <td className="py-2 px-4 border">{userService.user.username}</td>
              <td className="py-2 px-4 border">{userService.service.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserServicesReport;
