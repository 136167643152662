// src/components/UserSelectBox.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchAssignedUsers, fetchUsers } from '../services/apiService';

const UserSelectBox = () => {
  const { authTokens, impersonateUser, setImpersonatedUserId } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUsers = async () => {
      try {
        if (authTokens.userRole === 'admin') {
          const fetchedUsers = await fetchAssignedUsers(authTokens);
          setUsers(fetchedUsers);
        } else if (authTokens.userRole === 'super_admin') {
          const fetchedAllUsers = await fetchUsers(authTokens);
          const filteredUsers = fetchedAllUsers.filter((user) => user.role === 'user');
          setUsers(filteredUsers);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    loadUsers();
  }, [authTokens]);

  // Ordenar los usuarios alfabéticamente por nombre de usuario
  const sortedUsers = [...users].sort((a, b) => a.username.localeCompare(b.username));

  const handleChange = (event) => {
    const userId = event.target.value;
    if (userId === 'default') {
      setImpersonatedUserId(null);
    } else {
      impersonateUser(userId);
      navigate('/dashboard');
    }
  };

  return (
    <select
      onChange={handleChange}
      className={
        `bg-white text-gray-800 font-medium py-1 
        px-2 text-sm rounded-lg shadow-sm border border-gray-300 
        focus:outline-none focus:border-blue-500`
      }
      defaultValue="default"
    >
      <option value="default" disabled>Seleccione un usuario</option>
      {sortedUsers.map((user) => (
        <option key={user.id} value={user.id}>{user.username}</option>
      ))}
    </select>
  );
};

export default UserSelectBox;
