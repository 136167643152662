import React from 'react';

function Footer() {
  return (
    <footer className="footer-background">
      <p>
        ©
        {new Date().getFullYear()}
        {' '}
        Petrotec. Todos los derechos reservados.
      </p>
    </footer>
  );
}

export default Footer;
