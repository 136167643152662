import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import UserSelectBox from '../UserSelectBox';
import { useService } from '../../context/ServiceContext';
import menuItemsByService from '../../categories/serviceMenus';

const menuItemsByRole = {
  super_admin: [
    { name: 'Inicio', path: '/dashboard' },
    {
      name: 'Estaciones',
      path: '/estaciones',
      requiresConfirmation: true, // Sigue requiriendo confirmación
    },
    { name: 'Registro de Usuarios', path: '/userregistrationform' },
    { name: 'Administración de Usuarios', path: '/adminusermanagement' },
    { name: 'Editar Usuarios', path: '/superadminmanagement' },
    { name: 'Reporte de Servicios', path: '/user-services-report' },
  ],
  admin: [{ name: 'Inicio', path: '/dashboard' }],
  user: [{ name: 'Inicio', path: '/dashboard' }],
};

const menuItemsByRole2 = {
  super_admin: [],
  admin: [{ name: 'Inicio', path: '/dashboard' }],
  user: [{ name: 'Inicio', path: '/dashboard' }],
};

function Navbar() {
  const {
    authTokens, clearAuthTokens, impersonatedUserId, stopImpersonation, userRole, menuUpdateTrigger,
  } = useContext(AuthContext);
  const { selectedService } = useService();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [secondMenuItems, setSecondMenuItems] = useState([]);
  const isAuthenticated = !!authTokens;
  const navigate = useNavigate();

  const handleReloadMenu = useCallback(() => {
    console.log('Reloading menu...');
    const role = authTokens ? authTokens.userRole : 'guest';
    let items2 = menuItemsByRole2[role];
    if (selectedService && menuItemsByService[selectedService]) {
      items2 = menuItemsByService[selectedService];
    }
    setSecondMenuItems(items2);
    console.log(`Menu items set for role: ${role}`);
  }, [authTokens, selectedService]);

  useEffect(() => {
    const getUserRole = () => {
      if (impersonatedUserId) {
        return 'user';
      }
      if (authTokens) {
        return authTokens.userRole;
      }
      return 'guest';
    };

    const role = getUserRole();
    let items2 = menuItemsByRole2[role];
    if (selectedService && menuItemsByService[selectedService]) {
      items2 = menuItemsByService[selectedService];
    }
    setSecondMenuItems(items2);

    console.log(`Menu items set for role: ${role}`);
  }, [authTokens, impersonatedUserId, userRole, menuUpdateTrigger, selectedService]);

  const handleStopImpersonation = () => {
    stopImpersonation();
    navigate('/dashboard'); // Navegar a una ruta segura después de detener la suplantación

    // Ejecutar la función handleReloadMenu después de 2 segundos
    setTimeout(() => {
      handleReloadMenu();
    }, 2000);
  };

  const handleSignOut = () => {
    clearAuthTokens();
    navigate('/');
    setTimeout(() => {
      window.location.reload();
    }, 500); // Añadimos un pequeño retraso para permitir que la navegación termine
  };

  // Función para manejar la confirmación antes de navegar
  const handleMenuClick = (path, requiresConfirmation) => {
    if (requiresConfirmation) {
      const confirmed = window.confirm(
        'Al abrir esta sección, la aplicación se ralentizará y la carga puede tardar entre 2 y 5 minutos. ¿Desea continuar?',
      );
      if (!confirmed) {
        return; // Si el usuario no confirma, no se realiza la navegación
      }
    }
    navigate(path);
    setIsMenuOpen(false);
  };

  // Use an effect to trigger menu reload on component mount
  useEffect(() => {
    console.log('Component mounted, triggering menu reload...');
    handleReloadMenu();
  }, [handleReloadMenu, menuUpdateTrigger]);

  return (
    <nav className="nav-background fixed top-0 w-full flex flex-col md:flex-col items-center justify-between py-4 px-4">
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="text-white md:hidden z-20"
        aria-label="Toggle menu"
        type="button"
      >
        <FaBars size={24} />
      </button>
      <div className={`menu-container ${isMenuOpen ? 'open' : ''} w-full md:w-auto md:flex-col`}>
        {userRole === 'super_admin' && (
          <ul className="menu-list md:flex-row md:relative md:top-auto md:left-0
          md:w-auto md:bg-transparent z-10 flex-col items-center md:space-x-4"
          >
            {menuItemsByRole.super_admin.map((item) => (
              <li key={item.path} className="text-center flex w-full md:w-auto">
                <button
                  className="bg-green-500 text-white py-2 px-6
                  rounded-md hover:bg-green-600 transition duration-300 w-full md:w-auto"
                  type="button"
                  onClick={() => handleMenuClick(item.path, item.requiresConfirmation)}
                >
                  {item.name}
                </button>
              </li>
            ))}
            {['admin', 'super_admin'].includes(authTokens?.userRole) && <UserSelectBox />}
            {authTokens?.userRole === 'super_admin' && impersonatedUserId && (
              <button
                onClick={handleStopImpersonation}
                type="button"
                className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded invisible md:visible"
              >
                Salir de Estación
              </button>
            )}
          </ul>
        )}
        <div className="second-menu-container flex-col md:flex-row items-center w-full md:space-x-4">
          <ul className="second-menu-list md:flex-row md:relative md:top-auto md:left-0
          md:w-auto md:bg-transparent z-10 flex-col items-center md:space-x-4"
          >
            {secondMenuItems.map((item) => (
              <li key={item.path} className="text-center flex w-full md:w-auto">
                <NavLink
                  to={item.path}
                  className={({ isActive }) => `${isActive ? 'bg-green-700' : 'bg-green-500'} text-white py-2 px-6 
                  rounded-md hover:bg-green-600 transition duration-300 w-full md:w-auto`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
            {['admin'].includes(authTokens?.userRole) && <UserSelectBox />}
          </ul>
          {isAuthenticated && (
            <div className="flex items-center border-l border-gray-400 pl-4 ml-auto md:ml-0">
              <span className="text-white mr-4">{authTokens.username}</span>
              <button
                className="btn-signout ml-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded"
                type="button"
                onClick={handleSignOut}
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
