import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function SignIn() {
  const { setAuthTokens, triggerMenuUpdate } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (error) setError('');
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/sign_in`, {
        email,
        password,
      });

      const accessToken = response.headers['access-token'];
      const { client, uid } = response.headers;
      const { role, id, username } = response.data.data;

      setAuthTokens({
        'access-token': accessToken,
        client,
        uid,
        userRole: role,
        userId: id,
        username,
      });

      navigate('/dashboard');

      // Forzar la actualización del menú después de 2 segundos
      setTimeout(() => {
        console.log('Triggering menu update from SignIn...');
        triggerMenuUpdate();
      }, 2000);
    } catch (error) {
      setError('Correo electrónico o contraseña incorrectos.');
      console.error('Error al iniciar sesión:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-cover" style={{ marginTop: '-8rem' }}>
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <img src="/images/logo2.png" alt="Logo de Petrotec" className="mx-auto h-34 w-auto" />
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`
                  appearance-none rounded-none relative block w-full 
                  px-3 py-2 border border-gray-300 placeholder-gray-500 
                  text-gray-900 rounded-t-md focus:outline-none 
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 
                  sm:text-sm
                `}
                placeholder="Email"
                value={email}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className={`
                  appearance-none rounded-none relative block w-full
                  px-3 py-2 border border-gray-300 placeholder-gray-500
                  text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm
                `}
                placeholder="Password"
                value={password}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {error && <div className="text-red-500 text-center mt-2">{error}</div>}
          <div>
            <button
              type="submit"
              className={`
                group relative w-full flex justify-center
                py-2 px-4 border border-transparent text-sm
                font-medium rounded-md text-white bg-indigo-600
                hover:bg-indigo-700 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-indigo-500
              `}
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
