import { useState, useEffect } from 'react';
import {
  fetchAdmins,
  fetchUsers,
  assignUserToAdmin,
  unassignUserFromAdmin,
} from '../services/apiService';

function useAdminUserManagement(authTokens) {
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    async function loadAdminsAndUsers() {
      const loadedAdmins = await fetchAdmins(authTokens);
      const loadedUsers = await fetchUsers(authTokens);

      // Ordenar los usuarios y administradores alfabéticamente por username
      loadedAdmins.sort((a, b) => a.username.localeCompare(b.username));
      loadedUsers.sort((a, b) => a.username.localeCompare(b.username));

      setAdmins(loadedAdmins);
      setUsers(loadedUsers);
    }

    loadAdminsAndUsers();
  }, [authTokens]);

  const handleAdminSelection = (adminId) => {
    setSelectedAdmin(adminId);
    setSelectedUsers([]);
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) => (
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    ));
  };

  const refreshUsers = async () => {
    const loadedUsers = await fetchUsers(authTokens);

    // Ordenar los usuarios alfabéticamente por username
    loadedUsers.sort((a, b) => a.username.localeCompare(b.username));

    setUsers(loadedUsers);
  };

  const handleAssignUsers = async (userId, adminId) => {
    try {
      await assignUserToAdmin(adminId, [userId], authTokens);
      console.log('Asignación exitosa');
      alert('Usuario asignado correctamente al administrador');
      refreshUsers(); // Actualiza la lista de usuarios para reflejar los cambios
    } catch (error) {
      console.error('Error al asignar el usuario al administrador:', error);
      alert('Error al asignar el usuario');
    }
  };

  const handleUnassignAdmin = async (userId, adminId) => {
    try {
      await unassignUserFromAdmin(adminId, userId, authTokens);
      alert('Administrador desvinculado exitosamente');
      refreshUsers();
    } catch (error) {
      alert('Error al desvincular el administrador');
    }
  };

  return {
    admins,
    users,
    selectedAdmin,
    selectedUsers,
    refreshUsers,
    handleAdminSelection,
    handleUserSelection,
    handleAssignUsers,
    handleUnassignAdmin,
  };
}

export default useAdminUserManagement;
