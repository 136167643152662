import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import useAdminUserManagement from '../hooks/useAdminUserManagement';
import UserList from '../components/UserList';

export default function AdminUserManagement() {
  const { authTokens } = useContext(AuthContext);
  const {
    admins,
    users,
    handleAssignUsers,
    handleUnassignAdmin,
  } = useAdminUserManagement(authTokens);

  // Filtrar solo los usuarios que no son admin ni superadmin
  const filteredUsers = users.filter((user) => user.role === 'user');

  return (
    <div className="mt-8 mx-4 md:mx-8 lg:mx-16 xl:mx-32 relative pb-16">
      <div className="text-center mb-8">
        <img src="/images/logo2.png" alt="Logo Petrotec" className="mx-auto h-36 w-auto" style={{ marginTop: '-3rem' }} />
        <h1 className="text-3xl font-bold my-8">Administrador de Usuarios</h1>
      </div>
      <UserList
        users={filteredUsers}
        admins={admins}
        onAssignAdmin={handleAssignUsers}
        onUnassignAdmin={handleUnassignAdmin}
      />
    </div>
  );
}
