// src/categories/CategoryPage.js
import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle, FaFileAlt } from 'react-icons/fa';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { AuthContext } from '../context/AuthContext';
import {
  fetchTitles, fetchCommentsForTitle, fetchDocumentsForTitle, fetchUserCategory, updateUserCategory,
} from '../services/apiService';
import {
  handleCommentChange, handleCommentSubmit, handleDownload,
  handleDeleteComment, handleDocumentUpload, handleDocumentDelete,
  handleChangeDocumentStatus,
} from '../utils/actions';
import TitleList from '../components/TitleList';
import titlesByCategory from './titlesCategories';
import categoryIdMapping from './categoryIdMapping';
import 'react-circular-progressbar/dist/styles.css';

function CategoryPage({ category, pageTitle }) {
  const [titles, setTitles] = useState([]);
  const [newComments, setNewComments] = useState({});
  const [documentIndicator, setDocumentIndicator] = useState('');
  const [requirementsIndicator, setRequirementsIndicator] = useState('');
  const [dbDocumentIndicator, setDbDocumentIndicator] = useState('');
  const [dbRequirementsIndicator, setDbRequirementsIndicator] = useState('');
  const [dbTotalIndicator, setDbTotalIndicator] = useState('');
  const { authTokens, impersonatedUserId } = useContext(AuthContext);

  const fetchUserCategoryData = useCallback(async () => {
    if (!categoryIdMapping[category]) return;

    const categoryId = categoryIdMapping[category];
    const userCategory = await fetchUserCategory(authTokens, impersonatedUserId || authTokens.userId, categoryId);

    if (userCategory) {
      setDbDocumentIndicator(`${userCategory.documents_uploaded} de ${userCategory.documents_total} documentos subidos`);
      setDbRequirementsIndicator(`${userCategory.documents_completed} de ${userCategory.documents_total} documentos completados`);
      setDbTotalIndicator(`${userCategory.documents_total} documentos totales`);
    }
  }, [authTokens, impersonatedUserId, category]);

  const synchronizeWithDb = useCallback(async (documentsUploaded, documentsCompleted, totalDocuments) => {
    if (!categoryIdMapping[category]) return;

    const categoryId = categoryIdMapping[category];
    const userId = impersonatedUserId || authTokens.userId;

    const data = {};
    if (documentsUploaded !== null) {
      data.documents_uploaded = documentsUploaded;
    }
    if (documentsCompleted !== null) {
      data.documents_completed = documentsCompleted;
    }
    if (totalDocuments !== null) {
      data.documents_total = totalDocuments;
    }
    await updateUserCategory(authTokens, userId, categoryId, data, impersonatedUserId);
    fetchUserCategoryData();
  }, [authTokens, impersonatedUserId, category, fetchUserCategoryData]);

  const updateDocumentIndicator = useCallback((updatedTitles) => {
    const totalTitles = titlesByCategory[category].length;
    const titlesWithAtLeastOneDocument = updatedTitles.filter((title) => title.documents.length > 0).length;
    setDocumentIndicator(`${titlesWithAtLeastOneDocument} de ${totalTitles} documentos subidos`);
    synchronizeWithDb(titlesWithAtLeastOneDocument, null, totalTitles);
  }, [category, synchronizeWithDb]);

  const updateRequirementsIndicator = useCallback((updatedTitles) => {
    const totalTitles = titlesByCategory[category].length;
    const titlesWithAllDocumentsComplete = updatedTitles.filter(
      (title) => title.documents.length > 0 && title.documents.every((document) => document.status === 'complete'),
    ).length;
    setRequirementsIndicator(`${titlesWithAllDocumentsComplete} de ${totalTitles} documentos completos`);
    synchronizeWithDb(null, titlesWithAllDocumentsComplete, totalTitles);
  }, [category, synchronizeWithDb]);

  useEffect(() => {
    const isAdminAndImpersonating = (
      authTokens?.userRole === 'admin' || authTokens?.userRole === 'super_admin') && impersonatedUserId;
    const shouldLoadData = authTokens?.userRole === 'user' || isAdminAndImpersonating;

    if (shouldLoadData) {
      const loadTitlesCommentsAndDocuments = async () => {
        const fetchedTitles = await fetchTitles(authTokens, impersonatedUserId || authTokens.userId, category);
        const titlesWithCommentsAndDocumentsPromises = fetchedTitles.map(async (title) => {
          const [comments, documents] = await Promise.all([
            fetchCommentsForTitle(title.id, authTokens, impersonatedUserId || authTokens.userId),
            fetchDocumentsForTitle(title.id, authTokens, impersonatedUserId || authTokens.userId),
          ]);
          return { ...title, comments, documents };
        });
        const titlesWithCommentsAndDocuments = await Promise.all(titlesWithCommentsAndDocumentsPromises);
        const filteredTitles = titlesWithCommentsAndDocuments.filter((title) => titlesByCategory[category].includes(title.id));

        setTitles(filteredTitles);
        updateDocumentIndicator(filteredTitles);
        updateRequirementsIndicator(filteredTitles);
        fetchUserCategoryData();
      };

      loadTitlesCommentsAndDocuments();
    } else if ((authTokens?.userRole === 'admin' || authTokens?.userRole === 'super_admin') && !impersonatedUserId) {
      setTitles([]);
    }
  }, [authTokens, impersonatedUserId, category, updateDocumentIndicator, updateRequirementsIndicator, fetchUserCategoryData]);

  const getProgressColor = (uploaded, total) => {
    if (total === 0) return 'gray'; // Gris para casos de 0 de 0
    const percentage = (uploaded / total) * 100;
    if (percentage < 25) return 'red';
    if (percentage < 50) return 'orange';
    if (percentage < 99) return 'yellow';
    return 'green';
  };

  const calculatePercentage = (uploaded, total) => (total === 0 ? 0 : (uploaded / total) * 100);

  if ((authTokens?.userRole === 'admin' || authTokens?.userRole === 'super_admin') && !impersonatedUserId) {
    return <div>Por favor, seleccione un usuario para acceder a la información.</div>;
  }

  return (
    <div className="mt-8 mx-4 md:mx-8 lg:mx-16 xl:mx-32">
      <div className="text-center">
        <img src="/images/logo2.png" alt="Logo Petrotec" className="mx-auto h-24 w-auto" style={{ marginTop: '-3rem' }} />
        <h1 className="text-3xl font-bold my-8">{pageTitle}</h1>
      </div>
      <div className="flex justify-around mb-8">
        <div className="bg-pending p-4 rounded-lg shadow text-center flex flex-col items-center">
          <h2 className="flex items-center justify-center text-lg font-semibold">
            <FaFileAlt className="mr-2" />
            Documentos Subidos
          </h2>
          <div className="flex items-center justify-center" style={{ width: '154px', height: '154px' }}>
            <CircularProgressbar
              value={(calculatePercentage(
                parseInt(documentIndicator.split(' de ')[0], 10),
                parseInt(documentIndicator.split(' de ')[1], 10),
              ))}
              styles={buildStyles({
                textColor: 'black',
                pathColor: getProgressColor(
                  parseInt(documentIndicator.split(' de ')[0], 10),
                  parseInt(documentIndicator.split(' de ')[1], 10),
                ),
                textSize: '16px',
              })}
            />
          </div>
          <p className="text-sm font-bold mt-2 text-center">{documentIndicator}</p>
        </div>
        <div className="bg-complete p-4 rounded-lg shadow text-center flex flex-col items-center">
          <h2 className="flex items-center justify-center text-lg font-semibold">
            <FaCheckCircle className="mr-2" />
            Documentos Verificados y Completos
          </h2>
          <div className="flex items-center justify-center" style={{ width: '154px', height: '154px' }}>
            <CircularProgressbar
              value={calculatePercentage(
                parseInt(requirementsIndicator.split(' de ')[0], 10),
                parseInt(requirementsIndicator.split(' de ')[1], 10),
              )}
              styles={buildStyles({
                textColor: 'black',
                pathColor: getProgressColor(
                  parseInt(requirementsIndicator.split(' de ')[0], 10),
                  parseInt(requirementsIndicator.split(' de ')[1], 10),
                ),
                textSize: '16px',
              })}
            />
          </div>
          <p className="text-sm font-bold mt-2 text-center">{requirementsIndicator}</p>
        </div>
        <div className="bg-pending p-4 rounded-lg shadow" style={{ display: 'none' }}>
          <h2 className="flex items-center text-lg font-semibold">
            <FaFileAlt className="mr-2" />
            Documentos Subidos en base de datos
          </h2>
          <p className="text-xl font-bold">{dbDocumentIndicator}</p>
        </div>
        <div className="bg-complete p-4 rounded-lg shadow" style={{ display: 'none' }}>
          <h2 className="flex items-center text-lg font-semibold">
            <FaCheckCircle className="mr-2" />
            Documentos Verificados y Completos en base de datos
          </h2>
          <p className="text-xl font-bold">{dbRequirementsIndicator}</p>
        </div>
        <div className="bg-complete p-4 rounded-lg shadow" style={{ display: 'none' }}>
          <h2 className="flex items-center text-lg font-semibold">
            <FaCheckCircle className="mr-2" />
            Documentos Totales en base de datos
          </h2>
          <p className="text-xl font-bold">{dbTotalIndicator}</p>
        </div>
      </div>
      <TitleList
        titles={titles}
        onDownload={handleDownload}
        onDocumentUpload={(e, titleId) => handleDocumentUpload(
          e,
          titleId,
          authTokens,
          setTitles,
          titles,
          impersonatedUserId,
          updateDocumentIndicator,
          updateRequirementsIndicator,
          fetchUserCategoryData,
        )}
        onDocumentDelete={(titleId, documentId) => handleDocumentDelete(
          titleId,
          documentId,
          authTokens,
          setTitles,
          titles,
          impersonatedUserId,
          updateDocumentIndicator,
          updateRequirementsIndicator,
          fetchUserCategoryData,
        )}
        handleChangeDocumentStatus={(titleId, documentId, newStatus) => handleChangeDocumentStatus(
          titleId,
          documentId,
          newStatus,
          authTokens,
          setTitles,
          titles,
          impersonatedUserId,
          updateDocumentIndicator,
          updateRequirementsIndicator,
          fetchUserCategoryData,
        )}
        onCommentSubmit={(e, titleId) => handleCommentSubmit(
          e,
          titleId,
          newComments,
          authTokens,
          setNewComments,
          setTitles,
          titles,
          impersonatedUserId,
        )}
        onCommentChange={(e, titleId) => handleCommentChange(e, titleId, setNewComments, newComments)}
        onCommentDelete={(titleId, commentId) => handleDeleteComment(
          titleId,
          commentId,
          authTokens,
          setTitles,
          titles,
          impersonatedUserId,
        )}
        newComments={newComments}
        authTokens={authTokens}
        userRole={authTokens?.userRole}
      />
    </div>
  );
}

CategoryPage.propTypes = {
  category: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default CategoryPage;
