import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Dashboard from './pages/Dashboard';
import UserRegistrationForm from './pages/UserRegistrationForm';
import DatosGenerales from './pages/DatosGenerales';
import SignIn from './pages/SignIn';
import AdminUserManagement from './pages/AdminUserManagement';
import SuperAdminManagement from './pages/SuperAdminManagement';
import EstacionesList from './pages/EstacionesList';
import UserEditForm from './pages/UserEditForm';
import UserSelection from './pages/UserSelection';
import UserServicesReport from './pages/UserServicesReport';
import ProtectedRoute from './components/ProtectedRoute';
// Anexo 30 Y 31 Año 2022
import Anexo30Y31Ano2022DatosGenerales from './pages/anexo30y31del2022/Anexo30Y31Ano2022DatosGenerales';
import Anexo30Y31Ano2022SistemasDeMedicion from './pages/anexo30y31del2022/Anexo30Y31Ano2022SistemasDeMedicion';
import Anexo30Y31Ano2022SGM from './pages/anexo30y31del2022/Anexo30Y31Ano2022SGM';
import Anexo30Y31Ano2022ProgramaInformatico from './pages/anexo30y31del2022/Anexo30Y31Ano2022ProgramaInformatico';
import Anexo30Y31Ano2022EvidenciasFotograficas from './pages/anexo30y31del2022/Anexo30Y31Ano2022EvidenciasFotograficas';
// Anexo 30 Y 31 Año 2023
import Anexo30Y31Ano2023DatosGenerales from './pages/anexo30y31del2023/Anexo30Y31Ano2023DatosGenerales';
import Anexo30Y31Ano2023SistemasDeMedicion from './pages/anexo30y31del2023/Anexo30Y31Ano2023SistemasDeMedicion';
import Anexo30Y31Ano2023SGM from './pages/anexo30y31del2023/Anexo30Y31Ano2023SGM';
import Anexo30Y31Ano2023ProgramaInformatico from './pages/anexo30y31del2023/Anexo30Y31Ano2023ProgramaInformatico';
import Anexo30Y31Ano2023EvidenciasFotograficas from './pages/anexo30y31del2023/Anexo30Y31Ano2023EvidenciasFotograficas';
// Anexo 30 Y 31 Año 2024
import Anexo30Y31Ano2024DatosGenerales from './pages/anexo30y31del2024/Anexo30Y31Ano2024DatosGenerales';
import Anexo30Y31Ano2024SistemasDeMedicion from './pages/anexo30y31del2024/Anexo30Y31Ano2024SistemasDeMedicion';
import Anexo30Y31Ano2024SGM from './pages/anexo30y31del2024/Anexo30Y31Ano2024SGM';
import Anexo30Y31Ano2024ProgramaInformatico from './pages/anexo30y31del2024/Anexo30Y31Ano2024ProgramaInformatico';
import Anexo30Y31Ano2024EvidenciasFotograficas from './pages/anexo30y31del2024/Anexo30Y31Ano2024EvidenciasFotograficas';
// Dictamen de Diseño
import DictamenDeDisenoDocumentos from './pages/dictamendediseño/DictamenDeDisenoDocumentos';
import DictamenDeDisenoPlanos from './pages/dictamendediseño/DictamenDeDisenoPlanos';
// NOM-016-CRE-2016
import Nom016InformacionGeneral from './pages/nom016cre2016/Nom016InformacionGeneral';
import Nom016Documentacion from './pages/nom016cre2016/Nom016Documentacion';
// Inspeccion anexo 30 y 31 año 2024 para transporte o distribucion
import Inspeccion30Y31Ano2024DatosGenerales from './pages/inspeccion30y31del2024transporte/Inspeccion30Y31Ano2024DatosGenerales';
import
Inspeccion30Y31Ano2024SistemasDeMedicion
  from
  './pages/inspeccion30y31del2024transporte/Inspeccion30Y31Ano2024SistemasDeMedicion';
import Inspeccion30Y31Ano2024SGM from './pages/inspeccion30y31del2024transporte/Inspeccion30Y31Ano2024SGM';
import
Inspeccion30Y31Ano2024ProgramaInformatico
  from
  './pages/inspeccion30y31del2024transporte/Inspeccion30Y31Ano2024ProgramaInformatico';
import Inspeccion30Y31Ano2024Politicas from './pages/inspeccion30y31del2024transporte/Inspeccion30Y31Ano2024Politicas';

function Layout() {
  const location = useLocation();
  const isSignInPage = location.pathname === '/';

  return (
    <div className="flex-container">
      {!isSignInPage && <Navbar />}
      <div className="flex-grow">
        <Routes>
          <Route
            path="/datosgenerales"
            element={(
              <ProtectedRoute
                element={<DatosGenerales />}
                roles={['admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/dashboard"
            element={(
              <ProtectedRoute
                element={<Dashboard />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/userregistrationform"
            element={(
              <ProtectedRoute
                element={<UserRegistrationForm />}
                roles={['super_admin']}
              />
)}
          />
          <Route
            path="/adminusermanagement"
            element={(
              <ProtectedRoute
                element={<AdminUserManagement />}
                roles={['super_admin']}
              />
)}
          />
          <Route
            path="/superadminmanagement"
            element={(
              <ProtectedRoute
                element={<SuperAdminManagement />}
                roles={['super_admin']}
              />
)}
          />
          <Route
            path="/estaciones"
            element={(
              <ProtectedRoute
                element={<EstacionesList />}
                roles={['super_admin']}
              />
)}
          />
          <Route
            path="/usereditform"
            element={(
              <ProtectedRoute
                element={<UserEditForm />}
                roles={['super_admin']}
              />
)}
          />
          <Route
            path="/edit-user/:userId"
            element={(
              <ProtectedRoute
                element={<UserEditForm />}
                roles={['super_admin']}
              />
)}
          />
          <Route
            path="/userselection"
            element={(
              <ProtectedRoute
                element={<UserSelection />}
                roles={['admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/user-services-report"
            element={(
              <ProtectedRoute
                element={<UserServicesReport />}
                roles={['super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2022datosgenerales"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2022DatosGenerales />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2022sistemasdemedicion"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2022SistemasDeMedicion />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2022sgm"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2022SGM />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2022programainformatico"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2022ProgramaInformatico />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2022evidenciasfotograficas"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2022EvidenciasFotograficas />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2023datosgenerales"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2023DatosGenerales />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2023sistemasdemedicion"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2023SistemasDeMedicion />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2023sgm"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2023SGM />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2023programainformatico"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2023ProgramaInformatico />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2023evidenciasfotograficas"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2023EvidenciasFotograficas />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2024datosgenerales"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2024DatosGenerales />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2024sistemasdemedicion"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2024SistemasDeMedicion />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2024sgm"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2024SGM />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2024programainformatico"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2024ProgramaInformatico />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/anexo30Y31ano2024evidenciasfotograficas"
            element={(
              <ProtectedRoute
                element={<Anexo30Y31Ano2024EvidenciasFotograficas />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/dictamendedisenodocumentos"
            element={(
              <ProtectedRoute
                element={<DictamenDeDisenoDocumentos />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/dictamendedisenoplanos"
            element={(
              <ProtectedRoute
                element={<DictamenDeDisenoPlanos />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/nom016informaciongeneral"
            element={(
              <ProtectedRoute
                element={<Nom016InformacionGeneral />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/nom016documentacion"
            element={(
              <ProtectedRoute
                element={<Nom016Documentacion />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/inspeccion30y31ano2024datosgenerales"
            element={(
              <ProtectedRoute
                element={<Inspeccion30Y31Ano2024DatosGenerales />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/inspeccion30y31ano2024sistemasdemedicion"
            element={(
              <ProtectedRoute
                element={<Inspeccion30Y31Ano2024SistemasDeMedicion />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/inspeccion30y31ano2024sgm"
            element={(
              <ProtectedRoute
                element={<Inspeccion30Y31Ano2024SGM />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/inspeccion30y31ano2024programainformatico"
            element={(
              <ProtectedRoute
                element={<Inspeccion30Y31Ano2024ProgramaInformatico />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />
          <Route
            path="/inspeccion30y31ano2024politicas"
            element={(
              <ProtectedRoute
                element={<Inspeccion30Y31Ano2024Politicas />}
                roles={['user', 'admin', 'super_admin']}
              />
)}
          />

          <Route path="/" element={<SignIn />} />
        </Routes>
      </div>
      {!isSignInPage && <Footer />}
    </div>
  );
}

function App() {
  return <Layout />;
}

export default App;
