import React, { useEffect, useContext } from 'react';
import ServiceBanners from '../components/ServiceBanners';
import UserServicesDashboard from '../components/UserServicesDashboard';
import { AuthContext } from '../context/AuthContext';

function Dashboard() {
  const { triggerMenuUpdate, justLoggedIn, setJustLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (justLoggedIn) {
      console.log('Just logged in, triggering menu update...');
      setTimeout(() => {
        console.log('Triggering menu update...');
        triggerMenuUpdate();
        setJustLoggedIn(false);
      }, 3000);
    }
  }, [justLoggedIn, triggerMenuUpdate, setJustLoggedIn]);

  return (
    <div className="mt-8 mx-4 md:mx-8 lg:mx-16 xl:mx-32">
      <div className="text-center mb-8">
        <img src="/images/logo2.png" alt="Logo Petrotec" className="mx-auto h-36 w-auto" style={{ marginTop: '-3rem' }} />
        <h1 className="text-3xl font-bold my-8">Bienvenido a Petrotec</h1>
      </div>
      <UserServicesDashboard />
      <div className="bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-xl font-semibold mb-4">Cómo empezar:</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            Selecciona la categoria en el
            <strong> menú superior</strong>
            {' '}
            y adjuntas las fotografías y documentacion requerida.
          </li>
          <li>
            Es importante subir la
            <strong> documentación y fotografías</strong>
            {' '}
            completas.
          </li>
          <li>
            Un asesor revisara la
            <strong> documentación</strong>
            {' '}
            y dara retroalimentación.
          </li>
          <li>Consulta la ayuda en cualquier momento si necesitas asistencia adicional.</li>
        </ul>

        <p className="mt-4">
          Nuestra plataforma está diseñada para facilitar la gestión y el análisis de tus datos.
          Si tienes preguntas o necesitas soporte, no dudes en contactarnos.
        </p>
        <p className="mt-4">
          Llámanos al
          <strong> 222 941 0578</strong>
          {' '}
          o envíanos un WhatssApp al
          <strong> 222 425 6935</strong>
          {' '}
        </p>
      </div>
      <ServiceBanners />
    </div>
  );
}

export default Dashboard;
