// src/components/UserTypeList.js
import React from 'react';
import PropTypes from 'prop-types';

function UserTypeList({
  title, users, onEdit, onDelete,
}) {
  // Ordenar los usuarios alfabéticamente por nombre de usuario
  const sortedUsers = [...users].sort((a, b) => a.username.localeCompare(b.username));

  return (
    <div className="w-full md:w-1/3 p-4">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <ul>
        {sortedUsers.map((user) => (
          <li key={user.id} className="mb-4 p-2 border rounded shadow">
            <p>
              {user.username}
              {' '}
              (
              {user.role}
              )
            </p>
            <button
              onClick={() => onEdit(user.id)}
              type="button"
              className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
            >
              Editar
            </button>
            <button
              onClick={() => onDelete(user.id)}
              type="button"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

UserTypeList.propTypes = {
  title: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  })).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UserTypeList;
