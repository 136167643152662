import React from 'react';
import PropTypes from 'prop-types';

function SimpleUserItem({ user, onSelectUser }) {
  return (
    <li style={{ marginBottom: '10px' }}>
      {/* Envuelve el contenido en un botón para cumplir con la accesibilidad */}
      <button
        style={{
          all: 'unset',
          cursor: 'pointer',
          display: 'block',
          width: '100%',
          textAlign: 'left',
        }}
        onClick={() => onSelectUser(user.id)}
        type="button"
        // onKeyPress no es necesario aquí ya que los botones son accesibles por teclado por defecto
      >
        {user.username}
      </button>
    </li>
  );
}

SimpleUserItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  onSelectUser: PropTypes.func.isRequired,
};

export default SimpleUserItem;
