import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchAssignedUsers } from '../services/apiService';
import SimpleUserItem from '../components/SimpleUserItem';

const UserSelectionPage = () => {
  const { authTokens, impersonateUser } = useContext(AuthContext);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadAssignedUsers = async () => {
      try {
        const fetchedUsers = await fetchAssignedUsers(authTokens);
        setAssignedUsers(fetchedUsers);
      } catch (error) {
        console.error('Error fetching assigned users:', error);
      }
    };

    loadAssignedUsers();
  }, [authTokens]);

  const handleSelectUser = (userId) => {
    impersonateUser(userId);
    navigate('/datosgenerales');
  };

  return (
    <div>
      <h2>Selección de Usuario</h2>
      <ul>
        {assignedUsers.map((user) => (
          <SimpleUserItem
            key={user.id}
            user={user}
            onSelectUser={handleSelectUser}
          />
        ))}
      </ul>
    </div>
  );
};

export default UserSelectionPage;
