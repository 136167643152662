// src/components/UserList.js
import React from 'react';
import PropTypes from 'prop-types';
import UserItem from './UserItem';

function UserList({
  users, admins, onAssignAdmin, onUnassignAdmin,
}) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {users.map((user) => (
        <UserItem
          key={user.id}
          user={user}
          admins={admins}
          onAssignAdmin={onAssignAdmin} // Aquí estaba el error, usa la prop directamente
          onUnassignAdmin={onUnassignAdmin}
        />
      ))}
    </div>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
    }),
  ).isRequired,
  admins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onAssignAdmin: PropTypes.func.isRequired,
  onUnassignAdmin: PropTypes.func.isRequired,
};

export default UserList;
