import React, { useState, useEffect, useContext } from 'react';
import { FaCheckCircle, FaFileAlt } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import {
  fetchTitles,
  fetchCommentsForTitle,
  fetchDocumentsForTitle,
} from '../services/apiService';
import {
  handleCommentChange,
  handleCommentSubmit,
  handleDownload,
  handleDeleteComment,
  handleDocumentUpload,
  handleDocumentDelete,
  handleChangeDocumentStatus,
} from '../utils/actions';
import TitleList from '../components/TitleList';
import titlesByCategory from '../categories/titlesCategories';

function DatosGenerales() {
  const [titles, setTitles] = useState([]);
  const [newComments, setNewComments] = useState({});
  const [documentIndicator, setDocumentIndicator] = useState('');
  const [requirementsIndicator, setRequirementsIndicator] = useState('');
  const { authTokens, impersonatedUserId } = useContext(AuthContext);

  const updateDocumentIndicator = (updatedTitles) => {
    const totalTitles = titlesByCategory.datosGenerales.length;
    const titlesWithAtLeastOneDocument = updatedTitles.filter(
      (title) => title.documents.length > 0,
    ).length;
    setDocumentIndicator(`${titlesWithAtLeastOneDocument} de ${totalTitles} documentos subidos`);
  };

  const updateRequirementsIndicator = (updatedTitles) => {
    const totalTitles = titlesByCategory.datosGenerales.length;
    const titlesWithAllDocumentsComplete = updatedTitles.filter((title) => title.documents.length > 0
             && title.documents.every((document) => document.status === 'complete')).length;
    setRequirementsIndicator(`${titlesWithAllDocumentsComplete} de ${totalTitles} documentos completos`);
  };

  useEffect(() => {
    const isAdminAndImpersonating = authTokens?.userRole === 'admin' && impersonatedUserId;
    const shouldLoadData = authTokens?.userRole === 'user' || isAdminAndImpersonating;

    if (shouldLoadData) {
      const loadTitlesCommentsAndDocuments = async () => {
        const fetchedTitles = await fetchTitles(authTokens, impersonatedUserId || authTokens.userId);
        const titlesWithCommentsAndDocumentsPromises = fetchedTitles.map(async (title) => {
          const [comments, documents] = await Promise.all([
            fetchCommentsForTitle(title.id, authTokens, impersonatedUserId || authTokens.userId),
            fetchDocumentsForTitle(title.id, authTokens, impersonatedUserId || authTokens.userId),
          ]);
          return { ...title, comments, documents };
        });
        const titlesWithCommentsAndDocuments = await Promise.all(titlesWithCommentsAndDocumentsPromises);
        const filteredTitles = titlesWithCommentsAndDocuments.filter(
          (title) => titlesByCategory.datosGenerales.includes(title.id),
        );

        setTitles(filteredTitles);
        updateDocumentIndicator(filteredTitles); // Actualizar el indicador después de establecer los títulos
        updateRequirementsIndicator(filteredTitles);
      };

      loadTitlesCommentsAndDocuments();
    } else if (authTokens?.userRole === 'admin' && !impersonatedUserId) {
      setTitles([]);
    }
  }, [authTokens, impersonatedUserId]);

  if (authTokens?.userRole === 'admin' && !impersonatedUserId) {
    return <div>Por favor, seleccione un usuario para acceder a la información.</div>;
  }

  return (
    <div className="mt-8 mx-4 md:mx-8 lg:mx-16 xl:mx-32">
      <div className="text-center">
        <img src="/images/logo2.png" alt="Logo Petrotec" className="mx-auto h-36 w-auto" style={{ marginTop: '-3rem' }} />
        <h1 className="text-3xl font-bold my-8">Datos Generales</h1>
      </div>
      <div className="flex justify-around mb-8">
        <div className="bg-pending p-4 rounded-lg shadow">
          <h2 className="flex items-center text-lg font-semibold">
            <FaFileAlt className="mr-2" />
            Documentos Subidos
          </h2>
          <p className="text-xl font-bold">{documentIndicator}</p>
        </div>
        <div className="bg-complete p-4 rounded-lg shadow">
          <h2 className="flex items-center text-lg font-semibold">
            <FaCheckCircle className="mr-2" />
            Documentos Verificados y Completos
          </h2>
          <p className="text-xl font-bold">{requirementsIndicator}</p>
        </div>
      </div>
      <TitleList
        titles={titles}
        onDownload={handleDownload}
        onDocumentUpload={(e, titleId) => handleDocumentUpload(
          e,
          titleId,
          authTokens,
          setTitles,
          titles,
          impersonatedUserId,
          updateDocumentIndicator,
          updateRequirementsIndicator,
        )}
        onDocumentDelete={(titleId, documentId) => handleDocumentDelete(
          titleId,
          documentId,
          authTokens,
          setTitles,
          titles,
          impersonatedUserId,
          updateDocumentIndicator,
          updateRequirementsIndicator,
        )}
        handleChangeDocumentStatus={(titleId, documentId, newStatus) => handleChangeDocumentStatus(
          titleId,
          documentId,
          newStatus,
          authTokens,
          setTitles,
          titles,
          impersonatedUserId,
          updateDocumentIndicator,
          updateRequirementsIndicator,
        )}
        onCommentSubmit={(e, titleId) => handleCommentSubmit(
          e,
          titleId,
          newComments,
          authTokens,
          setNewComments,
          setTitles,
          titles,
          impersonatedUserId,
        )}
        onCommentChange={(e, titleId) => handleCommentChange(e, titleId, setNewComments, newComments)}
        onCommentDelete={(titleId, commentId) => handleDeleteComment(
          titleId,
          commentId,
          authTokens,
          setTitles,
          titles,
          impersonatedUserId,
        )}
        newComments={newComments}
        authTokens={authTokens}
        userRole={authTokens?.userRole}
      />
    </div>
  );
}

export default DatosGenerales;
