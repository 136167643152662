import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => {
    const data = localStorage.getItem('authData');
    return data ? JSON.parse(data) : null;
  });

  const [impersonatedUserId, setImpersonatedUserId] = useState(() => {
    const data = localStorage.getItem('impersonatedUserId');
    return data ? JSON.parse(data) : null;
  });

  const [userRole, setUserRole] = useState(() => {
    const data = localStorage.getItem('userRole');
    return data ? JSON.parse(data) : 'guest';
  });

  const [menuUpdateTrigger, setMenuUpdateTrigger] = useState(0);

  const triggerMenuUpdate = () => {
    setMenuUpdateTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    if (authTokens) {
      localStorage.setItem('authData', JSON.stringify(authTokens));
      setUserRole(authTokens.userRole);
    } else {
      localStorage.removeItem('authData');
      setUserRole('guest');
    }
  }, [authTokens]);

  useEffect(() => {
    if (impersonatedUserId) {
      localStorage.setItem('impersonatedUserId', JSON.stringify(impersonatedUserId));
    } else {
      localStorage.removeItem('impersonatedUserId');
    }
  }, [impersonatedUserId]);

  const impersonateUser = (userId) => {
    setImpersonatedUserId(userId);
  };

  const stopImpersonation = () => {
    setImpersonatedUserId(null);
    if (authTokens) {
      setUserRole(authTokens.userRole);
    }
  };

  const updateTokens = (tokens) => {
    setAuthTokens(tokens);
  };

  const clearTokens = () => {
    setAuthTokens(null);
    setImpersonatedUserId(null);
    setUserRole('guest');
  };

  const value = {
    authTokens,
    setAuthTokens: updateTokens,
    clearAuthTokens: clearTokens,
    impersonatedUserId,
    impersonateUser,
    setImpersonatedUserId,
    userRole,
    stopImpersonation,
    triggerMenuUpdate,
    menuUpdateTrigger,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
