import {
  uploadDocument, postComment, deleteComment, deleteDocument, changeDocumentStatus,
} from '../services/apiService';

export const handleCommentChange = (e, titleId, setNewComments, newComments) => {
  setNewComments({ ...newComments, [titleId]: e.target.value });
};

export const handleDeleteComment = async (titleId, commentId, authTokens, setTitles, titles, impersonatedUserId) => {
  try {
    await deleteComment(titleId, commentId, authTokens, impersonatedUserId);
    const updatedTitles = titles.map((title) => {
      if (title.id === titleId) {
        const updatedComments = title.comments.filter((comment) => comment.id !== commentId);
        return { ...title, comments: updatedComments };
      }
      return title;
    });
    setTitles(updatedTitles);
  } catch (error) {
    console.error('Error al eliminar comentario:', error);
  }
};

export const handleCommentSubmit = async (
  e,
  titleId,
  newComments,
  authTokens,
  setNewComments,
  setTitles,
  titles,
  impersonatedUserId,
) => {
  e.preventDefault();
  try {
    const newComment = await postComment(titleId, newComments[titleId], authTokens, impersonatedUserId);
    console.log('Comentario enviado con éxito');

    if (!titles) {
      console.error('Titles está indefinido.');
      return;
    }

    const updatedTitles = titles.map((title) => {
      if (title.id === titleId) {
        const updatedComments = Array.isArray(title.comments) ? [...title.comments, newComment] : [newComment];
        return { ...title, comments: updatedComments };
      }
      return title;
    });

    setTitles(updatedTitles);
    setNewComments({ ...newComments, [titleId]: '' });
  } catch (error) {
    console.error('Error al enviar comentario:', error);
  }
};

export const handleDocumentUpload = async (
  e,
  titleId,
  authTokens,
  setTitles,
  titles,
  impersonatedUserId,
  updateDocumentIndicator,
  updateRequirementsIndicator,
) => {
  const file = e.target.files[0];
  if (!file) return;

  try {
    const response = await uploadDocument(titleId, file, authTokens, impersonatedUserId);
    console.log('Documento subido con éxito');
    const updatedTitles = titles.map((title) => {
      if (title.id === titleId) {
        return { ...title, documents: [...title.documents, response] };
      }
      return title;
    });
    setTitles(updatedTitles);
    updateDocumentIndicator(updatedTitles);
    updateRequirementsIndicator(updatedTitles);
  } catch (error) {
    console.error('Error al subir documento:', error);
  }
};

export const handleDocumentDelete = async (
  titleId,
  documentId,
  authTokens,
  setTitles,
  titles,
  impersonatedUserId,
  updateDocumentIndicator,
  updateRequirementsIndicator,
) => {
  try {
    await deleteDocument(titleId, documentId, authTokens, impersonatedUserId);
    const updatedTitles = titles.map((title) => {
      if (title.id === titleId) {
        const updatedDocuments = title.documents.filter((document) => document.id !== documentId);
        return { ...title, documents: updatedDocuments };
      }
      return title;
    });
    setTitles(updatedTitles);
    updateDocumentIndicator(updatedTitles);
    updateRequirementsIndicator(updatedTitles);
  } catch (error) {
    console.error('Error al eliminar documento:', error);
  }
};

export const handleDownload = async (documentId) => {
  console.log('Descargar documento', documentId);
};

export const handleChangeDocumentStatus = async (
  titleId,
  documentId,
  newStatus,
  authTokens,
  setTitles,
  titles,
  impersonatedUserId,
  updateDocumentIndicator,
  updateRequirementsIndicator,
  setLoading, // Añadimos este parámetro para manejar el estado de carga
) => {
  try {
    // Activar el estado de carga
    setLoading(true);

    if (!authTokens) {
      throw new Error('Tokens de autenticación no están disponibles.');
    }

    const updatedDocument = await changeDocumentStatus(titleId, documentId, newStatus, authTokens, impersonatedUserId);

    if (updatedDocument && updatedDocument.status === newStatus) {
      const updatedTitles = titles.map((title) => {
        if (title.id === titleId) {
          const updatedDocuments = title.documents.map((doc) => {
            if (doc.id === documentId) {
              return { ...doc, status: updatedDocument.status };
            }
            return doc;
          });
          return { ...title, documents: updatedDocuments };
        }
        return title;
      });

      setTitles(updatedTitles);
      updateDocumentIndicator(updatedTitles);
      updateRequirementsIndicator(updatedTitles);
    } else {
      console.error('La actualización del estado del documento no se reflejó en la respuesta del backend.');
    }
  } catch (error) {
    console.error('Error al cambiar el estado del documento:', error);
  } finally {
    // Desactivar el estado de carga
    setLoading(false);
  }
};
