import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchUserDetails, updateUser, fetchServices } from '../services/apiService';

const UserEditForm = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { authTokens } = useContext(AuthContext);
  const [userData, setUserData] = useState({
    email: '',
    username: '',
    password: '',
    service_ids: [],
  });
  const [services, setServices] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadUserDataAndServices = async () => {
      try {
        const userDetails = await fetchUserDetails(userId, authTokens);
        const allServices = await fetchServices(authTokens);
        setUserData({
          email: userDetails.email,
          username: userDetails.username,
          service_ids: userDetails.services.map((service) => service.id.toString()),
        });
        setServices(allServices);
      } catch (error) {
        console.error('Error al cargar los detalles del usuario o los servicios:', error);
        setError('Error al cargar los detalles del usuario o los servicios.');
      }
    };

    loadUserDataAndServices();
  }, [userId, authTokens]);

  const handleServiceChange = (serviceId) => {
    const newServiceIds = userData.service_ids.includes(serviceId)
      ? userData.service_ids.filter((id) => id !== serviceId)
      : [...userData.service_ids, serviceId];
    setUserData({ ...userData, service_ids: newServiceIds });
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const updatedUserData = {
      ...userData,
      service_ids: userData.service_ids.map(Number),
    };

    try {
      await updateUser(userId, updatedUserData, authTokens);
      console.log('Usuario actualizado con éxito');
      navigate('/superadminmanagement');
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
      setError('Error al actualizar el usuario. Por favor, verifica tus datos.');
    }
  };

  return (
    <div className="mt-8 mx-4 md:mx-8 lg:mx-16 xl:mx-32">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold my-8">Editar Usuario</h1>
      </div>
      <div className="bg-white shadow-lg rounded-lg p-8">
        {error && <div className="text-red-500">{error}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
            <input
              id="email"
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              required
              className={`
                mt-1 block w-full border border-gray-300
                rounded-md shadow-sm py-2 px-3 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
              `}
            />
          </div>

          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Nombre de Usuario:</label>
            <input
              id="username"
              type="text"
              name="username"
              value={userData.username}
              onChange={handleChange}
              required
              className={`
                mt-1 block w-full border border-gray-300
                rounded-md shadow-sm py-2 px-3 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
              `}
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Contraseña:</label>
            <input
              id="password"
              type="password"
              name="password"
              value={userData.password}
              onChange={handleChange}
              required
              className={`
                mt-1 block w-full border border-gray-300
                rounded-md shadow-sm py-2 px-3 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
              `}
            />
          </div>

          <div>
            <label htmlFor="services" className="block text-sm font-medium text-gray-700">Servicios Activos:</label>
            <div className="mt-1">
              {services.map((service) => (
                <div key={service.id} className="flex items-center mb-2">
                  <input
                    id={`service-${service.id}`}
                    type="checkbox"
                    name="service_ids"
                    value={service.id}
                    checked={userData.service_ids.includes(service.id.toString())}
                    onChange={() => handleServiceChange(service.id.toString())}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label htmlFor={`service-${service.id}`} className="ml-2 block text-sm text-gray-900">
                    {service.name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Actualizar
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserEditForm;
