// src/components/UserEstacionesList.js
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { fetchUserCategory } from '../services/apiService';
import 'react-circular-progressbar/dist/styles.css';
import categoryMappings from '../categories/categoryMappings';
import categoryIdMapping from '../categories/categoryIdMapping';

function UserEstacionesList({
  title, users, onImpersonate, authTokens,
}) {
  const [totalIndicators, setTotalIndicators] = useState({});

  const fetchUserCategoryData = useCallback(async (userId, category) => {
    if (!categoryIdMapping[category]) return undefined;

    const categoryId = categoryIdMapping[category];
    try {
      const userCategory = await fetchUserCategory(authTokens, userId, categoryId);
      return userCategory;
    } catch (error) {
      console.error(`Error fetching category data for ${category}:`, error);
      return undefined;
    }
  }, [authTokens]);

  useEffect(() => {
    const loadUserServices = async () => {
      const totals = {};

      await Promise.all(users.map(async (user) => {
        const userTotals = {};

        await Promise.all(user.services.map(async (service) => {
          const serviceTotals = {
            documents_uploaded: 0,
            documents_completed: 0,
            documents_total: 0,
          };
          const categories = categoryMappings[service.name] || [];

          await Promise.all(categories.map(async (category) => {
            const userCategory = await fetchUserCategoryData(user.id, category);
            if (userCategory) {
              serviceTotals.documents_uploaded += userCategory.documents_uploaded || 0;
              serviceTotals.documents_completed += userCategory.documents_completed || 0;
              serviceTotals.documents_total += userCategory.documents_total || 0;
            }
          }));

          userTotals[service.name] = serviceTotals;
        }));

        totals[user.id] = userTotals;
      }));

      setTotalIndicators(totals);
    };

    if (users.length > 0 && authTokens) {
      loadUserServices();
    }
  }, [users, fetchUserCategoryData, authTokens]);

  const getProgressColor = (uploaded, total) => {
    if (total === 0) return 'gray'; // Gris para casos de 0 de 0
    const percentage = (uploaded / total) * 100;
    if (percentage < 25) return 'red';
    if (percentage < 50) return 'orange';
    if (percentage < 99) return 'yellow';
    return 'green';
  };

  // Ordenar los usuarios alfabéticamente por nombre de usuario
  const sortedUsers = [...users].sort((a, b) => a.username.localeCompare(b.username));

  return (
    <div className="w-full p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">{title}</h2>
      <div className="space-y-8">
        {sortedUsers.map((user) => (
          <div key={user.id} className="p-4 border rounded shadow">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <p className="text-lg font-bold mr-4">
                  {user.username}
                  {' '}
                  (
                  {user.role}
                  )
                </p>
                {onImpersonate && (
                  <button
                    onClick={() => onImpersonate(user.id)}
                    type="button"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Acceder
                  </button>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {user.services.map((service) => (
                <div key={service.id} className="p-2 bg-gray-100 rounded shadow">
                  <h4 className="text-lg font-semibold text-center mb-2">{service.name}</h4>
                  <div className="flex flex-col items-center justify-center">
                    <div className="mb-2" style={{ width: '80px', height: '80px' }}>
                      <CircularProgressbar
                        value={
                          (totalIndicators[user.id]?.[service.name]?.documents_uploaded
                          / totalIndicators[user.id]?.[service.name]?.documents_total) * 100
                        }
                        styles={buildStyles({
                          textColor: 'black',
                          pathColor: getProgressColor(
                            totalIndicators[user.id]?.[service.name]?.documents_uploaded,
                            totalIndicators[user.id]?.[service.name]?.documents_total,
                          ),
                          textSize: '10px',
                        })}
                      />
                    </div>
                    <p className="text-sm font-bold mt-2 text-center">
                      Documentos subidos:
                      {' '}
                      {totalIndicators[user.id]?.[service.name]?.documents_uploaded || 0}
                      {' '}
                      de
                      {' '}
                      {totalIndicators[user.id]?.[service.name]?.documents_total || 0}
                    </p>
                    <div className="mt-2" style={{ width: '80px', height: '80px' }}>
                      <CircularProgressbar
                        value={
                          (totalIndicators[user.id]?.[service.name]?.documents_completed
                          / totalIndicators[user.id]?.[service.name]?.documents_total) * 100
                        }
                        styles={buildStyles({
                          textColor: 'black',
                          pathColor: getProgressColor(
                            totalIndicators[user.id]?.[service.name]?.documents_completed,
                            totalIndicators[user.id]?.[service.name]?.documents_total,
                          ),
                          textSize: '10px',
                        })}
                      />
                    </div>
                    <p className="text-sm font-bold mt-2 text-center">
                      Documentos completados:
                      {' '}
                      {totalIndicators[user.id]?.[service.name]?.documents_completed || 0}
                      {' '}
                      de
                      {' '}
                      {totalIndicators[user.id]?.[service.name]?.documents_total || 0}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

UserEstacionesList.propTypes = {
  title: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
  })).isRequired,
  onImpersonate: PropTypes.func.isRequired,
  authTokens: PropTypes.shape({
    accessToken: PropTypes.string,
  }).isRequired,
};

export default UserEstacionesList;
