/* eslint max-len: 0 */
const titlesByCategory = {
  // Cualquier modificación se debe replicar en app\models\concerns\title_categories.rb
  // Anexo 30 y 31 año 2022
  // datosGenerales: [1, 2, 3, 4, 5, 6, 7],
  anexo30Y31Ano2022DatosGenerales: [1, 2, 3, 4, 5, 6, 7],
  anexo30Y31Ano2022SistemasDeMedicion: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  anexo30Y31Ano2022SGM: [27, 28, 29, 30, 31, 32],
  anexo30Y31Ano2022ProgramaInformatico: [36, 37, 38, 39, 40, 41, 42, 43, 44],
  anexo30Y31Ano2022EvidenciasFotograficas: [48, 49, 50, 51],
  // Anexo 30 y 31 año 2023
  anexo30Y31Ano2023DatosGenerales: [55, 56, 57, 58, 59, 60, 61],
  anexo30Y31Ano2023SistemasDeMedicion: [65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77],
  anexo30Y31Ano2023SGM: [81, 82, 83, 84, 85, 86],
  anexo30Y31Ano2023ProgramaInformatico: [90, 91, 92, 93, 94, 95, 96, 97, 98],
  anexo30Y31Ano2023EvidenciasFotograficas: [102, 103, 104, 105],
  // Anexo 30 y 31 año 2024
  anexo30Y31Ano2024DatosGenerales: [109, 110, 111, 112, 113, 114, 115],
  anexo30Y31Ano2024SistemasDeMedicion: [119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131],
  anexo30Y31Ano2024SGM: [135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145],
  anexo30Y31Ano2024ProgramaInformatico: [149, 150, 151, 152, 153, 154, 155, 156, 157],
  anexo30Y31Ano2024EvidenciasFotograficas: [161, 162, 163, 164],
  // Dictamen de Diseño
  dictamenDeDisenoDocumentos: [168, 169, 170, 171, 172, 173, 174, 175, 176, 177],
  dictamenDeDisenoPlanos: [183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204],
  // NOM-016-CRE-2016
  nom016InformacionGeneral: [210, 211, 212, 213, 214, 215],
  nom016Documentacion: [219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229],
  // Inspeccion anexo 30 y 31 año 2024 para transporte o distribucion
  inspeccion30Y31Ano2024DatosGenerales: [235, 236, 237, 238, 239, 240],
  inspeccion30Y31Ano2024SistemasDeMedicion: [246, 247, 248, 249, 250],
  inspeccion30Y31Ano2024SGM: [256, 257, 258, 259, 260, 261],
  inspeccion30Y31Ano2024ProgramaInformatico: [267, 268, 269, 270, 271, 272, 273],
  inspeccion30Y31Ano2024Politicas: [279],

};

export default titlesByCategory;
