// src/categories/categoryIdMapping.js
const categoryIdMapping = {
  anexo30Y31Ano2022DatosGenerales: 1,
  anexo30Y31Ano2022SistemasDeMedicion: 2,
  anexo30Y31Ano2022SGM: 3,
  anexo30Y31Ano2022ProgramaInformatico: 4,
  anexo30Y31Ano2022EvidenciasFotograficas: 5,
  anexo30Y31Ano2023DatosGenerales: 6,
  anexo30Y31Ano2023SistemasDeMedicion: 7,
  anexo30Y31Ano2023SGM: 8,
  anexo30Y31Ano2023ProgramaInformatico: 9,
  anexo30Y31Ano2023EvidenciasFotograficas: 10,
  anexo30Y31Ano2024DatosGenerales: 11,
  anexo30Y31Ano2024SistemasDeMedicion: 12,
  anexo30Y31Ano2024SGM: 13,
  anexo30Y31Ano2024ProgramaInformatico: 14,
  anexo30Y31Ano2024EvidenciasFotograficas: 15,
  dictamenDeDisenoDocumentos: 16,
  dictamenDeDisenoPlanos: 17,
  nom016InformacionGeneral: 18,
  nom016Documentacion: 19,
  inspeccion30Y31Ano2024DatosGenerales: 20,
  inspeccion30Y31Ano2024SistemasDeMedicion: 21,
  inspeccion30Y31Ano2024SGM: 22,
  inspeccion30Y31Ano2024ProgramaInformatico: 23,
  inspeccion30Y31Ano2024Politicas: 24,
};

export default categoryIdMapping;
