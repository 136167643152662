// src/categories/categoryMappings.js
const categoryMappings = {
  'Anexo 30 y 31 2022': [
    'anexo30Y31Ano2022DatosGenerales',
    'anexo30Y31Ano2022SistemasDeMedicion',
    'anexo30Y31Ano2022SGM',
    'anexo30Y31Ano2022ProgramaInformatico',
    'anexo30Y31Ano2022EvidenciasFotograficas',
  ],
  'Anexo 30 y 31 2023': [
    'anexo30Y31Ano2023DatosGenerales',
    'anexo30Y31Ano2023SistemasDeMedicion',
    'anexo30Y31Ano2023SGM',
    'anexo30Y31Ano2023ProgramaInformatico',
    'anexo30Y31Ano2023EvidenciasFotograficas',
  ],
  'Anexo 30 y 31 2024': [
    'anexo30Y31Ano2024DatosGenerales',
    'anexo30Y31Ano2024SistemasDeMedicion',
    'anexo30Y31Ano2024SGM',
    'anexo30Y31Ano2024ProgramaInformatico',
    'anexo30Y31Ano2024EvidenciasFotograficas',
  ],
  'Dictamen de Diseño': [
    'dictamenDeDisenoDocumentos',
    'dictamenDeDisenoPlanos',
  ],
  'NOM-016-CRE-2016': [
    'nom016InformacionGeneral',
    'nom016Documentacion',
  ],
  'Anexo 30 y 31 para transporte o distribución 2024': [
    'inspeccion30Y31Ano2024DatosGenerales',
    'inspeccion30Y31Ano2024SistemasDeMedicion',
    'inspeccion30Y31Ano2024SGM',
    'inspeccion30Y31Ano2024ProgramaInformatico',
    'inspeccion30Y31Ano2024Politicas',
  ],
};

export default categoryMappings;
