import React from 'react';
import PropTypes from 'prop-types';

function UserItem({
  user, admins, onAssignAdmin, onUnassignAdmin,
}) {
  // Función para manejar el cambio en el selector de administradores
  const handleAdminChange = (e) => {
    const adminId = e.target.value;
    if (adminId) {
      onAssignAdmin(user.id, adminId);
    }
  };

  return (
    <div className="bg-white shadow rounded p-4 m-2 flex flex-col">
      <span className="font-bold mb-2">{user.username}</span>

      {/* Siempre muestra el selector de administradores */}
      <select
        onChange={handleAdminChange}
        defaultValue=""
        className="mt-2 p-2 border rounded"
      >
        <option value="" disabled>Asignar Administrador</option>
        {admins.map((admin) => (
          <option key={admin.id} value={admin.id}>{admin.username}</option>
        ))}
      </select>

      {/* Muestra la información de administrador asignado y el botón de desvincular, si existe */}
      {user.admin_info && user.admin_info.length > 0 && (
        user.admin_info.map((admin) => (
          <div key={admin.admin_id} className="mt-2 flex justify-between items-center">
            <span>
              Administrado por:
              {admin.admin_username}
            </span>
            <button
              onClick={() => onUnassignAdmin(user.id, admin.admin_id)}
              className="ml-2 bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded text-xs"
              type="button"
            >
              Desvincular
            </button>
          </div>
        ))
      )}
    </div>
  );
}

UserItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    admin_info: PropTypes.arrayOf(PropTypes.shape({
      admin_id: PropTypes.number.isRequired,
      admin_username: PropTypes.string.isRequired,
    })),
  }).isRequired,
  admins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onAssignAdmin: PropTypes.func.isRequired,
  onUnassignAdmin: PropTypes.func.isRequired,
};

export default UserItem;
