import React, { useState, useContext, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import { adminCreateUser, fetchServices } from '../services/apiService';
import { AuthContext } from '../context/AuthContext';

const translateError = (error) => {
  const translations = {
    'Username has already been taken': 'El nombre de usuario ya ha sido tomado',
    'Email has already been taken': 'El correo electrónico ya ha sido tomado',
  };

  return error.map((err) => translations[err] || err);
};

const UserRegistrationForm = () => {
  const initialState = {
    email: '',
    username: '',
    role: 'user',
    password: '',
    passwordConfirmation: '',
    service_ids: [],
  };

  const [userData, setUserData] = useState(initialState);
  const [services, setServices] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { authTokens } = useContext(AuthContext);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const fetchedServices = await fetchServices(authTokens);
        setServices(fetchedServices);
      } catch (error) {
        console.error('Error loading services:', error);
      }
    };

    loadServices();
  }, [authTokens]);

  const isServiceSelected = (serviceId) => userData.service_ids.includes(serviceId.toString());

  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    let newServiceIds = [...userData.service_ids];
    if (checked) {
      newServiceIds.push(value);
    } else {
      newServiceIds = newServiceIds.filter((id) => id !== value);
    }
    setUserData({ ...userData, service_ids: newServiceIds });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (userData.password !== userData.passwordConfirmation) {
      setError('La contraseña y la confirmación de la contraseña no coinciden.');
      return;
    }

    try {
      const response = await adminCreateUser(userData, authTokens);
      console.log('Usuario registrado:', response);
      setSuccess('Registro exitoso. Bienvenido a la plataforma!');
      setUserData(initialState);
    } catch (error) {
      console.error('Error al registrar el usuario:', error.response ? error.response.data : error);
      const { errors } = error.response.data;
      const translatedErrors = translateError(Object.values(errors).flat());
      setError(translatedErrors.join('. ') || 'No se pudo registrar el usuario. Por favor, verifica tus datos.');
    }
  };

  return (
    <div className="mt-8 mx-4 md:mx-8 lg:mx-16 xl:mx-32">
      <div className="text-center mb-8">
        <img src="/images/logo2.png" alt="Logo Petrotec" className="mx-auto h-36 w-auto" style={{ marginTop: '-3rem' }} />
        <h1 className="text-3xl font-bold my-8">Registro de Usuarios</h1>
      </div>
      <div className="bg-white shadow-lg rounded-lg p-8">
        {error && <div className="text-red-500">{error}</div>}
        {success && <div className="text-green-500">{success}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
            <input
              id="email"
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              required
              className={`
                mt-1 block w-full border border-gray-300 rounded-md
                shadow-sm py-2 px-3 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
              `}
            />
          </div>

          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Nombre de Usuario:</label>
            <input
              id="username"
              type="text"
              name="username"
              value={userData.username}
              onChange={handleChange}
              required
              className={`
                mt-1 block w-full border border-gray-300
                rounded-md shadow-sm py-2 px-3 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
              `}
            />
          </div>

          <div>
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">Rol:</label>
            <select
              id="role"
              name="role"
              value={userData.role}
              onChange={handleChange}
              required
              className={`
                mt-1 block w-full border border-gray-300
                rounded-md shadow-sm py-2 px-3 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
              `}
            >
              <option value="">Seleccionar Rol</option>
              <option value="user">Estación</option>
              <option value="admin">Inspector</option>
              <option value="super_admin">Gerente</option>
            </select>
          </div>

          <div>
            <p className="block text-sm font-medium text-gray-700">Servicios Activos:</p>
            <div className="mt-1">
              {services.map((service) => (
                <div key={service.id} className="flex items-center mb-2">
                  <input
                    id={`service-${service.id}`}
                    type="checkbox"
                    name="service_ids"
                    value={service.id}
                    checked={isServiceSelected(service.id)}
                    onChange={handleServiceChange}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label htmlFor={`service-${service.id}`} className="ml-2 block text-sm text-gray-900">
                    {service.name}
                  </label>
                  {isServiceSelected(service.id) && <FaCheck className="ml-2 text-green-500" />}
                </div>
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Contraseña:</label>
            <input
              id="password"
              type="password"
              name="password"
              value={userData.password}
              onChange={handleChange}
              required
              className={`
                mt-1 block w-full border border-gray-300
                rounded-md shadow-sm py-2 px-3 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
              `}
            />
          </div>

          <div>
            <label
              htmlFor="passwordConfirmation"
              className="block text-sm font-medium text-gray-700"
            >
              Confirmación de Contraseña:
            </label>
            <input
              id="passwordConfirmation"
              type="password"
              name="passwordConfirmation"
              value={userData.passwordConfirmation}
              onChange={handleChange}
              required
              className={`
                mt-1 block w-full border border-gray-300
                rounded-md shadow-sm py-2 px-3 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
              `}
            />
          </div>

          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Registrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserRegistrationForm;
