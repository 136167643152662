// src/pages/SuperAdminManagement.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import {
  fetchAdmins, fetchUsers, fetchSuperAdmins, deleteUser,
} from '../services/apiService';
import UserTypeList from '../components/UserTypeList';

const SuperAdminManagement = () => {
  const { authTokens } = useContext(AuthContext);
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [superAdmins, setSuperAdmins] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const fetchedAdmins = await fetchAdmins(authTokens);
      const fetchedUsers = await fetchUsers(authTokens);
      const fetchedSuperAdmins = await fetchSuperAdmins(authTokens);
      setAdmins(fetchedAdmins);
      setUsers(fetchedUsers);
      setSuperAdmins(fetchedSuperAdmins);
    };

    loadData();
  }, [authTokens]);

  const handleEdit = (userId) => {
    navigate(`/edit-user/${userId}`);
  };

  const handleDelete = async (userId, userType) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este usuario?')) {
      try {
        await deleteUser(userId, authTokens);
        // Actualiza el estado para reflejar la eliminación
        if (userType === 'user') {
          setUsers(users.filter((user) => user.id !== userId));
        } else if (userType === 'admin') {
          setAdmins(admins.filter((admin) => admin.id !== userId));
        } else if (userType === 'superAdmin') {
          setSuperAdmins(superAdmins.filter((superAdmin) => superAdmin.id !== userId));
        }
      } catch (error) {
        alert(error.message || 'Error al eliminar el usuario');
      }
    }
  };

  return (
    <div className="flex flex-wrap justify-between">
      <UserTypeList
        title="Inspectores"
        users={admins}
        onEdit={(userId) => handleEdit(userId)}
        onDelete={(userId) => handleDelete(userId, 'admin')}
      />
      <UserTypeList
        title="Estaciones"
        users={users.filter((user) => user.role === 'user')} // Filtrar solo usuarios
        onEdit={(userId) => handleEdit(userId)}
        onDelete={(userId) => handleDelete(userId, 'user')}
      />
      <UserTypeList
        title="Gerentes"
        users={superAdmins}
        onEdit={(userId) => handleEdit(userId)}
        onDelete={(userId) => handleDelete(userId, 'superAdmin')}
      />
    </div>
  );
};

export default SuperAdminManagement;
