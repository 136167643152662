import React from 'react';
import {
  FaBuilding, FaOilCan, FaTruckMoving, FaGasPump, FaWarehouse, FaDraftingCompass, FaHammer, FaTools, FaVial, FaFileContract,
} from 'react-icons/fa';

const serviceGroups = [
  {
    groupName: 'NOM-005-ASEA-2016',
    services: [
      {
        id: 1,
        name: 'Etapa de diseño',
        icon: <FaDraftingCompass />,
        description: 'Proceso de diseño de instalaciones.',
      },
      {
        id: 2,
        name: 'Etapa de construcción',
        icon: <FaHammer />,
        description: 'Construcción de infraestructura.',
      },
      {
        id: 3,
        name: 'Etapa de operación y mantenimiento',
        icon: <FaTools />,
        description: 'Operación y mantenimiento continuos.',
      },
    ],
  },
  {
    groupName: 'NOM-016-CRE-2016',
    services: [
      {
        id: 4,
        name: 'Muestreo y Análisis de calidad de combustibles',
        icon: <FaVial />,
        description: 'Análisis detallado de calidad de combustibles.',
      },
      {
        id: 5,
        name: 'Dictamen de especificaciones de calidad',
        icon: <FaFileContract />,
        description: 'Especificaciones detalladas de los petrolíferos.',
      },
    ],
  },
  {
    groupName: 'ANEXO 30 Y 31',
    services: [
      {
        id: 6,
        name: 'Areas Contractuales',
        icon: <FaBuilding />,
        description: 'Gestión de áreas contractuales.',
      },
      {
        id: 7,
        name: 'Estaciones de Proceso',
        icon: <FaOilCan />,
        description: 'Mantenimiento y operación de estaciones de proceso.',
      },
      {
        id: 8,
        name: 'Producción de Petrolíferos',
        icon: <FaGasPump />,
        description: 'Control y manejo en la producción de petrolíferos.',
      },
      {
        id: 9,
        name: 'Terminales de Almacenamiento y Áreas de Almacenamiento para Usos Propios',
        icon: <FaWarehouse />,
        description: 'Gestión de terminales de almacenamiento.',
      },
      {
        id: 10,
        name: 'Transporte o Distribución',
        icon: <FaTruckMoving />,
        description: 'Logística y transporte de petrolíferos.',
      },
      {
        id: 11,
        name: 'Estaciones de Servicio',
        icon: <FaGasPump />,
        description: 'Operación y gestión de estaciones de servicio.',
      },
    ],
  },
];

const ServiceBanner = () => (
  <div className="mt-8">
    <h2 className="text-2xl font-semibold text-center mb-6">Nuestros Servicios</h2>
    {serviceGroups.map((group) => (
      <div key={group.groupName}>
        <h3 className="text-xl font-bold mb-4">{group.groupName}</h3>
        <div className="flex flex-wrap justify-center">
          {group.services.map((service) => (
            <div key={service.id} className="service-container m-4 p-4 bg-white shadow-lg rounded-lg flex flex-col items-center">
              <div className="h-16 w-16 mb-4 text-4xl text-blue-500">{service.icon}</div>
              <h4 className="text-lg font-semibold text-center">{service.name}</h4>
              <p className="text-center">{service.description}</p>
              <a
                href="https://asesoriapetrotec.com/services/"
                target="_blank"
                rel="noopener noreferrer"
                className="service-banner-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Ver más
              </a>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default ServiceBanner;
