import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const ServiceContext = createContext();

export const ServiceProvider = ({ children }) => {
  const [selectedService, setSelectedService] = useState(null);

  const selectService = (service) => {
    setSelectedService(service);
  };

  return (
    <ServiceContext.Provider value={{ selectedService, selectService }}>
      {children}
    </ServiceContext.Provider>
  );
};

ServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useService = () => useContext(ServiceContext);
