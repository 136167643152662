// src/pages/EstacionesList.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchUsers } from '../services/apiService';
import UserEstacionesList from '../components/UserEstacionesList';

const EstacionesList = () => {
  const { authTokens, impersonateUser } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const fetchedUsers = await fetchUsers(authTokens);
      setUsers(fetchedUsers);
    };

    loadData();
  }, [authTokens]);

  const handleImpersonate = (userId) => {
    impersonateUser(userId);
    navigate('/dashboard');
  };

  if (!authTokens) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="flex flex-wrap justify-between">
      <UserEstacionesList
        title="Estaciones"
        users={users.filter((user) => user.role === 'user')} // Filtrar solo usuarios
        onImpersonate={handleImpersonate}
        authTokens={authTokens}
      />
    </div>
  );
};

export default EstacionesList;
