import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = ({ element, roles }) => {
  const { authTokens } = useContext(AuthContext);

  if (!authTokens) {
    // Si el usuario no está autenticado, redirigir a la página de inicio de sesión
    return <Navigate to="/" />;
  }

  if (roles && roles.length > 0 && !roles.includes(authTokens.userRole)) {
    // Si el usuario no tiene el rol requerido, redirigir al dashboard
    return <Navigate to="/dashboard" />;
  }

  // Si el usuario está autenticado y tiene el rol requerido, renderizar el elemento
  return element;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
};

ProtectedRoute.defaultProps = {
  roles: [],
};

export default ProtectedRoute;
