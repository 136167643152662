// src/services/apiService.js
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
const getAuthHeaders = (authTokens) => ({
  'access-token': authTokens['access-token'],
  client: authTokens.client,
  uid: authTokens.uid,
});

/* const getUrlWithImpersonation = (url, impersonatedUserId) => (
  impersonatedUserId ? `${url}&impersonated_user_id=${impersonatedUserId}` : url
); */

const getUrlWithImpersonation = (url, impersonatedUserId) => {
  const separator = url.includes('?') ? '&' : '?';
  return impersonatedUserId ? `${url}${separator}impersonated_user_id=${impersonatedUserId}` : url;
};

export const adminCreateUser = async (userData, authTokens) => {
  const response = await axios.post(`${BASE_URL}/users/admin_create`, { user: userData }, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

/* export const fetchTitles = async (authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/titles`, impersonatedUserId);
  const response = await axios.get(url, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
}; */

export const fetchTitles = async (authTokens, impersonatedUserId, category) => {
  console.log('Fetching titles for category:', category);
  const url = getUrlWithImpersonation(
    `${BASE_URL}/titles?category=${category}`,
    impersonatedUserId,
  );
  const response = await axios.get(url, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const fetchCommentsForTitle = async (titleId, authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/titles/${titleId}/comments`, impersonatedUserId);
  const response = await axios.get(url, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const fetchDocumentsForTitle = async (titleId, authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/titles/${titleId}/documents`, impersonatedUserId);
  const response = await axios.get(url, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const postComment = async (titleId, content, authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/titles/${titleId}/comments`, impersonatedUserId);
  const response = await axios.post(url, { content, title_id: titleId }, {
    headers: { ...getAuthHeaders(authTokens), 'Content-Type': 'application/json' },
  });
  return response.data;
};

export const deleteComment = async (titleId, commentId, authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/titles/${titleId}/comments/${commentId}`, impersonatedUserId);
  await axios.delete(url, {
    headers: getAuthHeaders(authTokens),
  });
};

export const uploadDocument = async (titleId, file, authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/titles/${titleId}/documents`, impersonatedUserId);
  const formData = new FormData();
  formData.append('document[file]', file);
  formData.append('document[title_id]', titleId);
  const response = await axios.post(url, formData, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const changeDocumentStatus = async (titleId, documentId, status, authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/titles/${titleId}/documents/${documentId}`, impersonatedUserId);
  try {
    const response = await axios.put(
      url,
      { status },
      { headers: getAuthHeaders(authTokens) },
    );
    return response.data;
  } catch (error) {
    console.error('Error al cambiar el estado del documento:', error);
    throw error;
  }
};

export const deleteDocument = async (titleId, documentId, authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/titles/${titleId}/documents/${documentId}`, impersonatedUserId);
  await axios.delete(url, {
    headers: getAuthHeaders(authTokens),
  });
};

export const fetchSuperAdmins = async (authTokens) => {
  const response = await axios.get(`${BASE_URL}/users/super_admins`, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const fetchAdmins = async (authTokens) => {
  const response = await axios.get(`${BASE_URL}/users/admins`, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const fetchUsers = async (authTokens) => {
  const response = await axios.get(`${BASE_URL}/users?role=user`, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const assignUserToAdmin = async (adminId, userIds, authTokens) => {
  const response = await axios.post(`${BASE_URL}/users/assign_user`,
    {
      admin_id: adminId,
      user_ids: userIds,
    },
    {
      headers: getAuthHeaders(authTokens),
    });
  return response.data;
};

export const unassignUserFromAdmin = async (adminId, userId, authTokens) => {
  try {
    const response = await axios.delete(`${BASE_URL}/users/unassign_user`, {
      data: { admin_id: adminId, user_id: userId },
      headers: getAuthHeaders(authTokens),
    });
    return response.data;
  } catch (error) {
    console.error('Error al desvincular usuario del administrador:', error);
    throw error;
  }
};

export const fetchLinkedUsers = async (authTokens, adminId) => {
  const response = await axios.get(`${BASE_URL}/users/linked_users?admin_id=${adminId}`, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const fetchAssignedUsers = async (authTokens) => {
  const response = await axios.get(`${BASE_URL}/users/assigned_users`, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const deleteUser = async (userId, authTokens) => {
  try {
    const response = await axios.delete(`${BASE_URL}/users/${userId}`, {
      headers: getAuthHeaders(authTokens),
    });
    return response.data;
  } catch (error) {
    console.error('Error al eliminar el usuario:', error);
    if (error.response && error.response.status === 422) {
      throw new Error('No se puede procesar la entidad. Verifica las dependencias del usuario.');
    } else {
      throw error;
    }
  }
};

export const fetchUserDetails = async (userId, authTokens) => {
  try {
    const response = await axios.get(`${BASE_URL}/users/${userId}`, {
      headers: {
        'access-token': authTokens['access-token'],
        client: authTokens.client,
        uid: authTokens.uid,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al cargar los detalles del usuario:', error);
    throw error;
  }
};

export const updateUser = async (userId, userData, authTokens) => {
  try {
    const response = await axios.put(`${BASE_URL}/users/${userId}`, { user: userData }, {
      headers: {
        'access-token': authTokens['access-token'],
        client: authTokens.client,
        uid: authTokens.uid,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al actualizar el usuario:', error);
    throw error;
  }
};

export const fetchServices = async (authTokens, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/services`, impersonatedUserId);
  const response = await axios.get(url, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const fetchUserCategory = async (authTokens, userId, categoryId) => {
  const url = `${BASE_URL}/users/${userId}/categories/${categoryId}`;
  const response = await axios.get(url, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const updateUserCategory = async (authTokens, userId, categoryId, data, impersonatedUserId) => {
  const url = getUrlWithImpersonation(`${BASE_URL}/users/${userId}/categories/${categoryId}`, impersonatedUserId);
  const response = await axios.put(url, { user_category: data }, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};

export const fetchUserServicesReport = async (authTokens) => {
  const response = await axios.get(`${BASE_URL}/user_services_report`, {
    headers: getAuthHeaders(authTokens),
  });
  return response.data;
};
