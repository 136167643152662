// src/components/UserServicesDashboard.js
import React, {
  useContext, useEffect, useState, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AuthContext } from '../context/AuthContext';
import { fetchUserDetails, fetchUserCategory } from '../services/apiService';
import menuItemsByService from '../categories/serviceMenus';
import categoryMappings from '../categories/categoryMappings';
import categoryIdMapping from '../categories/categoryIdMapping';
import { useService } from '../context/ServiceContext';

const UserServicesDashboard = () => {
  const { authTokens, impersonatedUserId } = useContext(AuthContext);
  const [services, setServices] = useState([]);
  const [categoryIndicators, setCategoryIndicators] = useState({});
  const [totalIndicators, setTotalIndicators] = useState({});
  const navigate = useNavigate();
  const { selectService } = useService();

  const fetchUserCategoryData = useCallback(async (userIdToFetch, category) => {
    if (!categoryIdMapping[category]) return undefined;

    const categoryId = categoryIdMapping[category];
    try {
      const userCategory = await fetchUserCategory(authTokens, userIdToFetch, categoryId);
      return userCategory;
    } catch (error) {
      console.error(`Error fetching category data for ${category}:`, error);
      return undefined;
    }
  }, [authTokens]);

  useEffect(() => {
    const serviceOrder = {
      'Anexo 30 y 31 2022': 1,
      'Anexo 30 y 31 2023': 2,
      'Anexo 30 y 31 2024': 3,
      'Dictamen de Diseño': 4,
      'NOM-016-CRE-2016': 5,
      'Anexo 30 y 31 para transporte o distribución 2024': 6,
    };

    const loadUserServices = async () => {
      if (authTokens && (impersonatedUserId || authTokens.userRole !== 'admin')) {
        try {
          const userIdToFetch = impersonatedUserId || authTokens.userId;
          const userDetails = await fetchUserDetails(userIdToFetch, authTokens);
          const sortedServices = userDetails.services.sort(
            (a, b) => (serviceOrder[a.name] || 0) - (serviceOrder[b.name] || 0),
          );
          setServices(sortedServices);

          const indicatorsPromises = sortedServices.flatMap((service) => {
            const categories = categoryMappings[service.name] || [];
            return categories.map(async (category) => {
              const userCategory = await fetchUserCategoryData(userIdToFetch, category);
              return { [category]: userCategory || {} };
            });
          });

          const indicatorsArray = await Promise.all(indicatorsPromises);
          const indicators = indicatorsArray.reduce((acc, curr) => ({ ...acc, ...curr }), {});
          setCategoryIndicators(indicators);

          const totals = {};
          sortedServices.forEach((service) => {
            const categories = categoryMappings[service.name] || [];
            totals[service.name] = categories.reduce((acc, category) => {
              const indicator = indicators[category] || {};
              acc.documents_uploaded += indicator.documents_uploaded || 0;
              acc.documents_completed += indicator.documents_completed || 0;
              acc.documents_total += indicator.documents_total || 0;
              return acc;
            }, { documents_uploaded: 0, documents_completed: 0, documents_total: 0 });
          });
          setTotalIndicators(totals);
        } catch (error) {
          console.error('Error al cargar los servicios del usuario:', error);
        }
      }
    };

    loadUserServices();
  }, [authTokens, impersonatedUserId, fetchUserCategoryData]);

  const handleServiceClick = (serviceName) => {
    selectService(serviceName);
    const firstMenuItem = menuItemsByService[serviceName]?.[0];
    if (firstMenuItem) {
      navigate(firstMenuItem.path);
    }
  };

  const getProgressColor = (uploaded, total) => {
    if (total === 0) return 'gray'; // Gris para casos de 0 de 0
    const percentage = (uploaded / total) * 100;
    if (percentage < 25) return 'red';
    if (percentage < 50) return 'orange';
    if (percentage < 99) return 'yellow';
    return 'green';
  };

  const calculatePercentage = (uploaded, total) => (total === 0 ? 0 : (uploaded / total) * 100);

  let content;
  if (authTokens?.userRole === 'admin' && !impersonatedUserId) {
    content = (
      <div className="text-center text-lg font-semibold">
        Por favor, seleccione un usuario para acceder a la información de los servicios.
      </div>
    );
  } else if (services.length === 0) {
    content = <div className="text-center text-lg font-semibold">Actualmente no se tienen servicios asignados.</div>;
  } else {
    content = (
      <>
        <h3 className="text-xl font-semibold mb-4">Tus Servicios</h3>
        <div className="flex flex-wrap justify-center">
          {services.map((service) => (
            <div
              key={service.id}
              className="m-2 p-4 bg-blue-500 hover:bg-blue-700 text-white font-bold
              rounded transition duration-300 ease-in-out transform hover:-translate-y-1
              hover:scale-110"
            >
              <button
                type="button"
                onClick={() => handleServiceClick(service.name)}
                className="w-full text-center py-2 px-4 bg-white text-blue-700 font-semibold rounded"
              >
                {service.name}
              </button>
              <div className="mt-2">
                {categoryMappings[service.name]?.map((category) => (
                  <div key={category} className="bg-gray-100 text-black p-2 mt-2 rounded" style={{ display: 'none' }}>
                    <div>
                      Documentos subidos:
                      {categoryIndicators[category]?.documents_uploaded || 0}
                      {' '}
                      de
                      {categoryIndicators[category]?.documents_total || 0}
                    </div>
                    <div>
                      Documentos completados:
                      {categoryIndicators[category]?.documents_completed || 0}
                      {' '}
                      de
                      {categoryIndicators[category]?.documents_total || 0}
                    </div>
                  </div>
                ))}
                <div className="bg-green-100 text-black p-2 mt-2 rounded text-center flex flex-col items-center">
                  <h3 className="text-lg font-semibold">Documentos subidos totales</h3>
                  <div className="flex items-center justify-center" style={{ width: '104px', height: '104px' }}>
                    <CircularProgressbar
                      value={calculatePercentage(
                        totalIndicators[service.name]?.documents_uploaded || 0,
                        totalIndicators[service.name]?.documents_total || 1,
                      )}
                      styles={buildStyles({
                        textColor: 'black',
                        pathColor: getProgressColor(
                          totalIndicators[service.name]?.documents_uploaded,
                          totalIndicators[service.name]?.documents_total,
                        ),
                        textSize: '16px',
                      })}
                    />
                  </div>
                  <p className="text-sm font-bold mt-2 text-center">
                    {totalIndicators[service.name]?.documents_uploaded || 0}
                    {' '}
                    de
                    {' '}
                    {totalIndicators[service.name]?.documents_total || 0}
                  </p>
                  <h3 className="text-lg font-semibold mt-4">Documentos completados totales</h3>
                  <div className="flex items-center justify-center" style={{ width: '104px', height: '104px' }}>
                    <CircularProgressbar
                      value={calculatePercentage(
                        totalIndicators[service.name]?.documents_completed || 0,
                        totalIndicators[service.name]?.documents_total || 1,
                      )}
                      styles={buildStyles({
                        textColor: 'black',
                        pathColor: getProgressColor(
                          totalIndicators[service.name]?.documents_completed,
                          totalIndicators[service.name]?.documents_total,
                        ),
                        textSize: '16px',
                      })}
                    />
                  </div>
                  <p className="text-sm font-bold mt-2 text-center">
                    {totalIndicators[service.name]?.documents_completed || 0}
                    {' '}
                    de
                    {' '}
                    {totalIndicators[service.name]?.documents_total || 0}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  return <div className="bg-white shadow-lg rounded-lg p-8 my-4">{content}</div>;
};

export default UserServicesDashboard;
